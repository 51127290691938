import { ConfigServiceContext } from '@grandstand-web/bally-web-shared/src/services/config/ConfigService'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { ReactNode, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useContinuousPlayEnabled } from '../hooks/useContinuousPlayEnabled'
import { getDeviceIsSafari, getDeviceType } from '../hooks/useDeviceInfo'
import { VideoError, useVideoService } from '../hooks/useVideoService'
import { isNotInProd } from '../utils/envUtils'
import { Logger } from '../utils/logger'
import LoadingSpinner from './LoadingSpinner'
import NextIdDisplay from './NextIdDisplay'
import CommonVideoErrorView from './VideoErrorView'

const ShakaPlayerContainer = dynamic(import('./ShakaPlayer'), { ssr: false })

type VideoPlayerProps = {
  videoId: string
  videoStartTime?: number
  muted?: boolean
  chromeless?: boolean
  shouldRenderErrorOverlay?: boolean
  shouldRenderLoadingSpinner?: boolean
  shouldTrackVideoAnalytics?: boolean
  disabled?: boolean
  children?: ReactNode
  image?: string
  start?: Date
  onPlayerCanPlayThrough?: () => void
  showLoadingUntilPlaying?: boolean
  VideoErrorView?: ({ error }: { error: VideoError }) => JSX.Element
}

const LoadingSpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoadingSpinnerOverLay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: var(--canvas);
  top: 0;
  left: 0;
`

const VideoPlayer = (props: VideoPlayerProps) => {
  const router = useRouter()
  const { currentConfig } = useContext(ConfigServiceContext)
  const { error, src } = useVideoService({ videoId: props.videoId, useRouter, isDisabled: props.disabled })
  const [playerLoaded, setPlayerLoaded] = useState(false)
  const [firstPlayStarted, setFirstPlayStarted] = useState(false)
  const { isWebContinuousPlayEnabled, isConnectedWebContinuousPlayEnabled } = useContinuousPlayEnabled()
  const SelectedVideoErrorView = props.VideoErrorView ?? CommonVideoErrorView

  useEffect(() => {
    Logger.of('VideoPlayer').debug('src changed', { src })
  }, [src])

  if (error) {
    const shouldRenderErrorOverlay = props.shouldRenderErrorOverlay ?? true
    return shouldRenderErrorOverlay ? <SelectedVideoErrorView error={error} /> : <></>
  }

  if (!src?.src || src.src.length === 0) {
    const shouldRenderLoadingSpinner = props.shouldRenderLoadingSpinner ?? true
    Logger.of('VideoPlayer').debug('shouldRenderLoadingSpinner', { src, shouldRenderLoadingSpinner })
    return shouldRenderLoadingSpinner ? (
      <LoadingSpinnerWrapper>
        <LoadingSpinner />
      </LoadingSpinnerWrapper>
    ) : (
      <></>
    )
  }

  const showLoadingOverLay =
    (props.showLoadingUntilPlaying ?? false) && (!playerLoaded || (!firstPlayStarted && !props.disabled))
  Logger.of('VideoPlayer').debug('showLoadingOverLay', {
    src,
    showLoadingOverLay,
    showLoadingUntilPlaying: props.showLoadingUntilPlaying,
    playerLoaded,
    firstPlayStarted,
    disabled: props.disabled
  })

  const rawVideoResponse = src.raw
  const drmLicenseInfo = rawVideoResponse.drm?.licenseUrl
  const deviceType = getDeviceType()
  const isSafari = getDeviceIsSafari()
  let config: any = {
    manifest: {
      segmentRelativeVttTiming: true
    }
  }

  const isConnectedWeb = () => currentConfig?.apiPlatform === 'cw'

  const handleVideoPlaybackEnded = () => {
    if (src.raw.next_id) {
      const connectedWeb = isConnectedWeb()
      const nextVideoBasePath = connectedWeb ? 'video' : 'game-details'
      if ((connectedWeb && isConnectedWebContinuousPlayEnabled) || (!connectedWeb && isWebContinuousPlayEnabled)) {
        router.push(`/${nextVideoBasePath}/${src.raw.next_id}`)
      }
    } else {
      // Back to home
      router.push('/')
    }
  }

  if (isSafari) {
    config.drm = {
      servers: { 'com.apple.fps': drmLicenseInfo ?? '' },
      advanced: {
        'com.apple.fps': {
          serverCertificateUri: drmLicenseInfo + 'cert/ballysports'
        } as shaka.extern.AdvancedDrmConfiguration
      }
    } as unknown as shaka.extern.DrmConfiguration
  } else if (deviceType === 'tv_xboxone') {
    config.drm = {
      servers: {
        'com.microsoft.playready': drmLicenseInfo
      }
    }
  } else {
    config.drm = {
      servers: { 'com.widevine.alpha': drmLicenseInfo ?? '' }
      // TODO: When adding this drm doesn't seem to work. I think
      //       there might be some investigation here
      // advanced: {
      //   'com.widevine.alpha': {
      //     audioRobustness: RobustnessLevel,
      //     videoRobustness: RobustnessLevel
      //   } as shaka.extern.AdvancedDrmConfiguration
      // }
    } as unknown as shaka.extern.DrmConfiguration
  }
  Logger.of('VideoPlayer').debug('drm config set', { src, config })

  return (
    <>
      <ShakaPlayerContainer
        drmToken={rawVideoResponse.drm?.token}
        config={config}
        src={src}
        // playerRef={ref}
        autoPlay={props.disabled ? false : true}
        chromeless={props.chromeless ?? false}
        muted={props.muted ?? false}
        onPlayerCanPlayThrough={props.onPlayerCanPlayThrough}
        shouldTrackVideoAnalytics={props.shouldTrackVideoAnalytics}
        disabled={props.disabled ?? false}
        startTime={props.videoStartTime}
        start={props.start}
        poster={props.image}
        onPlayerLoaded={() => {
          setPlayerLoaded(true)
        }}
        onPlay={() => {
          if (!firstPlayStarted) {
            setFirstPlayStarted(true)
          }
        }}
        onPlaybackEnded={handleVideoPlaybackEnded}
      >
        {props.children}
      </ShakaPlayerContainer>
      {showLoadingOverLay && (
        <LoadingSpinnerOverLay>
          <LoadingSpinnerWrapper>
            <LoadingSpinner />
          </LoadingSpinnerWrapper>
        </LoadingSpinnerOverLay>
      )}
      {isNotInProd() && <NextIdDisplay nextId={src.raw.next_id} />}
    </>
  )
}

export default VideoPlayer
