import { useEffect, useState } from 'react'
import { isNotInProd } from '../utils/envUtils'
import { ItemStorage } from '../utils/storageUtils'

const storageKey = 'debug.v1'
type IsDebug = boolean | 'auto'
const storage = new ItemStorage<boolean>(storageKey, false)

const getSaved = (): boolean => {
  try {
    const href = window.location.href
    // set storage to true (meaning, we'll ALWAYS be in debug mode regardless of env)
    if (href.includes('debug=true')) {
      storage.setItem(true)
    }
    // set storage to false (meaning, we'll ONLY be in debug mode if we're in a non-prod env)
    if (href.includes('debug=false')) {
      storage.setItem(false)
    }
    // return current storage value
    return storage.getItem()
  } catch (_) {
    // do not update storage
    // return current storage value
    return storage.getItem()
  }
}
// getIsDebug statically
// allow overriding auto with apiEnv param
export const getIsDebug = (): boolean => {
  const auto = isNotInProd()
  const saved = getSaved()
  // if saved === true, return it
  // else return auto (boolean)
  return saved || auto
}

export const useIsDebug = (): boolean => {
  // no need to pass apiEnv as getAuto will handle it
  const [isDebug, setIsDebug] = useState(getIsDebug())
  useEffect(() => {
    setIsDebug(getIsDebug())
    if (typeof window === 'undefined') {
      return
    }
    // listen for changes to window.location.href, and update state
    const handlePopstate = () => setIsDebug(getIsDebug())
    window.addEventListener('popstate', handlePopstate)
    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [])
  return isDebug
}
